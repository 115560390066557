<template lang="pug">

div
  v-btn(fixed dark fab bottom right color="orange" @click="showModal = true")
    v-icon add
  v-text-field(clearable prepend-inner-icon="mdi-magnify" label="Search" v-model="search")
  v-list
    v-list-item(v-for="feature in filteredFeatures" :key="feature['.key']")
      v-list-item-content
        v-list-item-title {{feature.name}}
      v-list-item-action
        v-btn(text color="orange" @click="navToFeature(feature)") Unit Page
  v-dialog(v-model="showModal" max-width="500px")
    v-card
      v-card-actions
        v-spacer
        v-btn(color="orange" text @click="showModal=false") Close
      v-card-text
        form
          v-text-field(v-model="newFeature.name" label="Name" type="text")
          v-text-field(v-model="newFeature.short" label="Short" type="text")
          v-text-field(v-model="newFeature.alters" label="Alters" type="text")
          v-text-field(v-model="newFeature.value" label="Value" type="text")
      v-card-actions
        v-btn(color="primary" @click="addFeature()") Add Feature
</template>
<script>
import modal from '@/components/modal'
import { mapGetters } from 'vuex'

function initialFeature () {
  return {
    name: '',
    short: '',
    alters: '',
    altersval: null
  }
}

export default {
  name: 'app',
  data () {
    return {
      showModal: false,
      selected: '',
      search: '',
      newFeature: initialFeature()
    }
  },
  methods: {
    navToFeature (feature) {
      this.$router.push({ path: `/features/${feature.id}` })
    },
    resetFeature () {
      this.showModal = false
      this.newFeature = initialFeature()
    },
    addFeature () {
      this.search = this.newFeature.name
      this.$store.dispatch('addFeature', this.newFeature)
      this.resetFeature()
    }
  },
  computed: {
    ...mapGetters([
      'features'
    ]),
    filteredFeatures () {
      var that = this
      var data = this.features
      return data.filter(feature => {
        var searchRegex = new RegExp(that.search, 'i')
        return searchRegex.test(feature.name)
      })
    }
  },
  components: {
    modal
  }
}
</script>
<style lang="stylus" scoped>

</style>
